.home-page-categorylist {
  border-width: 0 1px 1px 1px !important;
}

.home-page-categorylist .item .title {
  min-height: 0;
}

.first-char-capital:first-letter {
  text-transform:capitalize;
}